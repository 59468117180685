<template>
  <full-view title="Merchant Settings">
    <v-card flat>
      <v-list subheader three-line>
        <v-subheader>Stores Management</v-subheader>

        <v-list-item to="store-profiles">
          <v-list-item-avatar>
            <v-icon large>mdi-store</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.route.storeProfiles')}}</v-list-item-title>
            <v-list-item-subtitle>
              Manage your store profiles
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </full-view>
</template>

<script>
import FullView from "@/common/layouts/FullView";

export default {
  name: "BusinessSettings",
  components: { FullView },
};
</script>
